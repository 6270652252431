.navbar {
    background-color: #fff;
    height: 70px;
    padding: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 994px) {
        background-color: rgb(245, 244, 244);
    }

    .pagename {
        padding-left: 24px;
        color: $flame !important;
        font-size: 24px;
        font-weight: 700;

        a {
            color: $flame !important;
        }
    }

    .toggle {
        display: none;
    }

    .navbar-collapse {
        justify-content: flex-end;

        .navbar-nav {
            align-items: center;
            .user {
                a {
                    font-size: 16px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    .avatar {
                        margin-left: 22px;
                        .user-picture {
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .logout {
                display: flex;
                justify-content: space-around;
                flex-direction: row;
                align-items: center;
                padding: 12px 26px 12px 25px;
                width: 150px;
                height: 44px;
                margin-left: 22px;
            }
        }
    }

    @media screen and (max-width: 450px) {
        display: flex;
        flex-wrap: wrap !important;
        width: 100%;
        margin-bottom: 30px;

        .toggle {
            display: inline;
            width: 50px;
        }

        .logout {
            width: 50px !important;

            .logout-text {
                display: none;
            }
        }

        .pagename {
            width: 100%;
            order: 2;
            text-align: center;
            margin-top: 30px;
        }
    }
}
