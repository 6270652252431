.content-panel {
    overflow: auto;
    max-height: 100%;
    position: relative;
    width: 100%;
    min-height: 100vh;

    .content-area {
        padding: 50px 0;
        background-color: #fff;
        min-height: 100vh;
    }

    @media screen and (min-width: 450px) {
        width: calc(100% - 88px);
        float: right;
        transition: width 0.5s ease-in-out;
        margin-right: 10px;

        &.narrow-sidebar {
            width: calc(100% - 88px);
        }
    }

    @media screen and (min-width: 992px) {
        width: calc(100% - 270px);
        float: right;
        transition: width 0.5s ease-in-out;
        margin-right: 10px;

        &.narrow-sidebar {
            width: calc(100% - 88px);
        }
    }
}
