.sidebar {
    position: fixed;
    width: 250px;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    font-weight: 200;
    background-color: $flame;
    background-size: cover;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    transition: all 0.5s ease-in-out;

    &.narrow-sidebar {
        width: 78px;
        overflow: hidden;
        overflow-y: scroll;

        .logo-container {
            display: flex;
            height: 130px;
            padding: 25px;
            margin-left: -170px;

            .logo {
                height: auto;
                width: 40px;

                &.horizontal.hidden {
                    visibility: hidden;
                    transition: all 0s ease-in-out;
                }
            }

            .logo-text {
                display: none;
            }
        }

        p,
        .chevron-icon {
            visibility: hidden;
            opacity: 0;
        }

        .nav li .nav-link {
            padding: 10px 3px 10px 10px;
        }
    }

    .sidebar-wrapper {
        position: relative;
        overflow: auto;
        z-index: 4;
    }

    .logo-container {
        display: flex;
        height: 130px;
        padding: 25px;
        transition: all 0.5s ease-in-out;
        position: relative;
        cursor: pointer;
        justify-content: center;

        .logo {
            height: auto;
            position: absolute;
            top: 0;
            width: 70px;
            margin-top: 20px;
            transition: all 0.5s;
        }

        .logo-text {
            height: auto;
            position: absolute;
            top: 90px;
            transition: all 0.5s;
            width: 50%;
            display: block;
        }
    }

    .nav {
        margin-top: 20px;
        display: block;

        li .nav-link {
            color: #fff;
            margin: 5px 15px;
            border-radius: 4px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            color: $salmon;
            height: 60px;

            position: relative;

            p {
                margin-left: 20px;
            }

            .chevron-icon {
                position: absolute;
                right: 0;
                transition: all 0.3s ease-in, opacity 1s ease-in;
                svg {
                    margin-right: 0;
                }
            }
        }

        li:hover > a,
        li.open > a,
        li.open > a:focus,
        li.open > a:hover {
            color: white;
            opacity: 1;
        }
        li.active {
            background: white;
        }
        li.active > a {
            color: black;
            opacity: 1;
        }
        p {
            margin: 0;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.2px;

            transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
        }
    }

    .toggle-sidebar {
        position: absolute;
        bottom: 5px;
        right: 0px;
        left: 4px;

        &.expanded {
            left: -28px;
        }

        cursor: pointer;
        color: rgb(255, 190, 187);
        width: 100%;
        text-align: right;

        &:hover {
            color: white;
        }
    }

    @media screen and (max-width: 992px) {
        width: 78px;
        overflow: hidden;
        overflow-y: scroll;

        & .logo-container {
            display: flex;
            height: 130px;
            padding: 25px;

            .logo {
                height: auto;
                width: 40px;

                &.horizontal.hidden {
                    visibility: hidden;
                    transition: all 0s ease-in-out;
                }
            }

            .logo-text {
                display: none;
            }
        }

        & p,
        & .chevron-icon {
            display: none;
        }
        &.show p,
        &.show .chevron-icon {
            display: block;
        }

        & .nav li .nav-link {
            padding: 10px 3px;
        }
    }

    @media screen and (max-width: 450px) {
        &.show {
            display: block;
            width: 280px;
        }

        &.hide {
            width: 0;
            overflow: hidden;

            p,
            .chevron-icon {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}
