.table {
    // width: 100%;
    .table-responsive {
        border-top: none !important;
    }

    .table-actions {
        padding: 24px 40px 0;

        .show-hide-columns {
            height: 40px;
            width: 40px;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f8f8f8;
            cursor: pointer;
            margin-bottom: 10px;
        }

        .options {
            div {
                padding: 0;
            }

            .col-selectors {
                display: flex;
                flex-wrap: wrap;

                .selector-item {
                    margin-right: 30px;
                    padding-bottom: 4px;

                    @media screen and (max-width: 1208px) {
                        width: 30%;
                        margin-right: 0;
                    }
                    @media screen and (max-width: 576px) {
                        width: 50%;
                        margin-right: 0;
                    }
                    @media screen and (max-width: 450px) {
                        width: 100%;
                        margin-right: 0;
                        padding-bottom: 4px;
                    }
                }

                .checkbox {
                    margin-right: 5px;
                    accent-color: $balaton;
                }
            }

            select.pagination-selector {
                border-radius: 8px;
                padding: 8px;
                margin-bottom: 16px;
            }
        }
    }

    .filter-header {
        background-color: #fff;
        border: none;
        @media screen and (max-width: 994px) {
            background-color: #f9ece8;
        }

        tr {
            th {
                padding: 10px 10px;
                input {
                    background: #f8f8f8;
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;
                    height: 40px;
                    margin-right: 20px;
                    padding: 10px;
                }
                :hover {
                    background-color: #fff;
                }

                .text-filter-container {
                    position: relative;

                    .search-icon {
                        position: absolute;
                        left: 8px;
                        bottom: -7px;
                    }

                    .search-input:focus + .search-icon {
                        display: none;
                    }
                }

                .select-filter-container {
                    select {
                        background: #f8f8f8;
                        border: 1px solid #e5e5e5;
                        border-radius: 8px;
                        height: 40px;
                        margin-right: 20px;
                        padding: 10px;
                    }
                }
            }
        }
    }
    .header-title {
        padding: 10px 10px;
        border: none;

        @media screen and (max-width: 994px) {
            filter: invert(0.9);
        }


        tr {
            background-color: $light;
            th {
                padding: 10px 10px;
                .title {
                    margin-right: 5px;
                }
            }
        }
        :hover {
            background-color: $light;
        }
    }
    tbody {

        &.empty-table {
            width: 100%;
            position: relative;
            height: 300px;
        .table-empty-state-message {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 300px;
            width: 100%;
            text-align:center;
            background-color:#e5e5e5;
            padding-left: 36px;
        }
        }

        input {
            background: #fff;
            border: 1px solid #e5e5e5;
            border-radius: 8px;
        }
        tr {
            min-height: 60px;
            background-color: #fff;
            color: $darkgray;

            &:hover {
                background-color: $pink;
            }

            td {
                min-height: 60px;
                padding: 10px 10px !important;
                width: fit-content;
                vertical-align: middle;
            }
        }
        tr:nth-child(even) {
            background-color: $light;

            &:hover {
                background-color: $pink;
            }
        }

        .table-image {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
        }
        .table-icon-container {
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 50%;
            background-color: $pink;
            color: $flame;
            width: 70px;
            height: 70px;
        }
    }
}
.pagination {
    border: none !important;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $darkgray;
}
